import { Outlet } from "react-router-dom";
import React from 'react';

const LayoutTimesheet = () => {


    return (
    <>
      <Outlet />
    </>
  )
};

export default LayoutTimesheet;