import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TimeEntry from "./TimeEntry";
// import { getDaysInMonth } from "../utils/dateUtils";
import './TimeTrackingApp.css';
import '../../output.css';
import { buscarTimesheetAPI, updateTimesheetAPI, createTimeSheetEmptyAPI } from '../../services/timesheet-api';

const usuarios = [
  { id: 1, nome: "DAVI" },
  { id: 2, nome: "VICENTE" },
  { id: 3, nome: "WILLIANS" },
  { id: 4, nome: "RONI" },
  { id: 5, nome: "LUIS" },
];

const TimeTrackingApp = () => {
  const { year, month, userId } = useParams(); // Captura ano e mês da URL
  const usuario = usuarios.find((u) => u.id === parseInt(userId));  
  const selectedMonth = parseInt(month);
  const [timeEntries, setTimeEntries] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  useEffect(() => {
    carregarTimesheet( userId, `${month}-${year}` );
    // showMessage();
  }, []);

  useEffect(() => {
    
  }, [timeEntries]); // Reage quando timeEntries muda


  const updateTimesheet = async (jsonData) => { //<--aqui
    try {
      const result = await updateTimesheetAPI(userId, `${month}-${year}`, jsonData); 

      if (result.success) {
        setResponseMessage("Success updading information!");  // Mensagem de sucesso
        showMessage();
      } else {
        setResponseMessage("Error!");  // Mensagem de sucesso
        showMessage();
      }
    } catch (error) {
      console.error("Erro inesperado ao carregar timesheet:", error);
    }
  };

  const handleTimeEntryChange = (index, entry) => {
    const newTimeEntries = [...timeEntries];
    newTimeEntries[index] = entry;
    setTimeEntries(newTimeEntries);

    updateTimesheet(newTimeEntries);
  };

  /**
   * Função principal para carregar o timesheet
   */
  const carregarTimesheet = async (userId, month) => {
    try {
      const result = await buscarTimesheetAPI(userId, month);

      if(result.success){
        if(result.data === ""){
          //create register on DB
          //insert on DB
          createTimeSheetEmpty([]);

          //process DB
          processarTimesheet([]);
        }else{
          processarTimesheet(result.data[0].json_data);
        }
      }else{
        setResponseMessage(JSON.stringify(result));
        setShowAlert(true);
      }
    } catch (error) {
      console.error("Erro inesperado ao carregar timesheet:", error);
    }
  };

  const createTimeSheetEmpty = async () => {
    try {
      const result = await createTimeSheetEmptyAPI(userId, `${month}-${year}`);

      if(result.success){
        //create silent
      }else{
        setResponseMessage(JSON.stringify(result));
        setShowAlert(true);
      }
    } catch (error) {
      console.error("Erro inesperado ao carregar timesheet:", error);
    }
  };

  /**
   * Processa os dados do timesheet e preenche os dias faltantes
   */
  const processarTimesheet = (existingData) => {
    const dataMap = mapearDadosExistentes(existingData);
    const fullMonthData = gerarTimesheetCompleto(dataMap);
    setTimeEntries(fullMonthData);
  };

  /**
   * Mapeia os dados existentes para facilitar a busca
   */
  const mapearDadosExistentes = (existingData) => {
    return new Map(existingData.map(item => [item.date, item]));
  };

  /**
   * Gera o timesheet completo com placeholders para dias ausentes
   */
  const gerarTimesheetCompleto = (dataMap) => {
    const fullMonthData = [];
    for (let day = 1; day <= 31; day++) {
      const date = formatarData(day);
      
      if (dataMap.has(date)) {
        fullMonthData.push(dataMap.get(date)); // Mantém os dados existentes
      } else {
        fullMonthData.push(criarEntradaVazia(date));
      }
    }
    return fullMonthData;
  };

  /**
   * Formata a data no padrão MM/DD/YYYY
   */
  const formatarData = (day) => {
    return `${month.toString().padStart(2, "0")}/${day.toString().padStart(2, "0")}/${year}`;
  };

  /**
   * Cria uma entrada vazia para um determinado dia
   */
  const criarEntradaVazia = (date) => {
    return {
      date,
      exitTime: { hour: "", minute: "", period: "" },
      entryTime: { hour: "", minute: "", period: "" },
      description: ""
    };
  };

  const showMessage = async () => {
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  }

  const calcularTotalHoras = () => {
    let totalMinutos = 0;
  
    timeEntries.forEach((entry) => {
      if (entry.entryTime?.hour && entry.exitTime?.hour && entry.entryTime?.period && entry.exitTime?.period ) {

        console.info("calculando: " + entry.exitTime?.period);

        const entradaHora = parseInt(entry.entryTime.hour, 10);
        const entradaMinuto = parseInt(entry.entryTime.minute, 10);
        const saidaHora = parseInt(entry.exitTime.hour, 10);
        const saidaMinuto = parseInt(entry.exitTime.minute, 10);
  
        // Converter para 24 horas
        const entradaEmMinutos =
          (entradaHora % 12 + (entry.entryTime.period === "PM" ? 12 : 0)) * 60 + entradaMinuto;
        const saidaEmMinutos =
          (saidaHora % 12 + (entry.exitTime.period === "PM" ? 12 : 0)) * 60 + saidaMinuto;
  
        if (saidaEmMinutos > entradaEmMinutos) {
          totalMinutos += saidaEmMinutos - entradaEmMinutos;
        }
      }
    });
  
    const horas = Math.floor(totalMinutos / 60);
    const minutos = totalMinutos % 60;
    return `${horas}h ${minutos}min`;
  };

  return (
  <div className="min-h-screen flex flex-col justify-center items-center bg-gray-50 p-6">
    {showAlert && (
      <div className="alert bg-blue-100 border border-red-400 px-4 py-3 rounded" style={{ opacity: showAlert ? 1 : 0, backgroundColor:"green", color: "white" }}>
        {responseMessage}
      </div>
    )}
    <h2 className="bg-blue font-bold text-gray-700 mb-4">
      Month - {selectedMonth}/{year}
    </h2>
    {usuario?.nome && <h2>User: {usuario.nome}</h2>}

    <h3>Total Hours: {calcularTotalHoras()}</h3>

    {/* <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-6" onClick={()=>populate()} >POPULATE</button> */}


    <div className="mt-6">
      {timeEntries.map((entry, index) => {
        return (
          <TimeEntry 
            key={index}
            date={entry.date}
            entryTime={entry.entryTime || { hour: "00", minute: "00", period: "AM" }}
            exitTime={entry.exitTime || { hour: "00", minute: "00", period: "AM" }}
            description={entry.description || ""}
            onChange={(updatedEntry) => handleTimeEntryChange(index, updatedEntry)}
          />
        );
      })}
    </div>

  </div>
);
};

export default TimeTrackingApp;
