import React from "react";

const TimePicker = ({ label, time, setTime }) => {
  
  const getSafeValue = (value) => {
    return value === null ? "" : value; // ou undefined
  };


  return (
    <div className="flex flex-col items-center">
      <p  className={`text-md font-medium mb-3 ${
        label === "Entry Time"
          ? "text-green-600"
          : label === "Exit Time" 
          ? "text-red-600"
          : "text-gray-700"
      }`}>{label}</p>
      <div className="flex gap-2 border-2 border-gray-300 rounded-md overflow-hidden p-2 bg-gray-100">
        <select
          value={getSafeValue(time.hour)}
          onChange={(e) => setTime({ ...time, hour: e.target.value })}
          className="w-16 h-12 text-lg text-center [text-align-last:center] bg-white border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-300"
        >
          <option value="">--</option>
          {[...Array(13).keys()].map((num) => (
            <option key={num} value={num.toString().padStart(2, "0")}>
              {num}
            </option>
          ))}
        </select>
        <select
          value={getSafeValue(time.minute)}
          onChange={(e) => setTime({ ...time, minute: e.target.value })}
          className="w-16 h-12 text-lg text-center [text-align-last:center] bg-white border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-300"
        >
          <option value="">--</option>
          <option value="00">00</option>
          <option value="30">30</option>
        </select>
        <select
          value={getSafeValue(time.period)}
          onChange={(e) => setTime({ ...time, period: e.target.value })}
          className="w-16 h-12 text-lg text-center [text-align-last:center] bg-white border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-300"
        >
          <option value="">--</option>
          <option value="AM">AM</option>
          <option value="PM">PM</option>
        </select>
      </div>
    </div>
  );
};

export default TimePicker;
