import axios from 'axios';
import env from '../config.js';

const BASE_URL = env.backendUrl;

const createTimeSheetEmptyAPI = async (userId, month) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/timesheets`, 
      {
        user_id: userId,
        month: month,
        json_data: [], 
      },
      {
        headers: {
          "Content-Type": "application/json", // Definir tipo de conteúdo como JSON
        },
      }
    );
    return { success: true, data: response.data };
  } catch (err) {
    console.error("Erro ao enviar dados:", err);
    return { success: false, error: err };
  }
};

// Função para buscar dados (GET ALL)
const buscarDados = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/timesheets`, // URL da sua API
    );
    return { success: true, data: response.data };
  } catch (err) {
    console.error("Erro ao buscar dados:", err);
    return { success: false, error: err };
  }
};

const buscarTimesheetAPI = async (userId, month) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/timesheets/search`,
      {
        params: { user_id: userId, month: month }, // Parâmetros da query string
      }
    );
    return { success: true, data: response.data };
  } catch (err) {
    console.error("Erro ao buscar timesheet:", err);
    return { success: false, error: err };
  }
};

const updateTimesheetAPI = async (userId, month, jsonData) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/timesheets/${userId}`,
      {
        user_id: userId,
        month: month,
        json_data: jsonData, 
      }
    );

    return { success: true, data: response.data };
  } catch (err) {
    console.error("Erro ao atualizar timesheet:", err);
    return { success: false, error: err };
  }
};


// Exportando as funções para serem usadas em outros arquivos
export { createTimeSheetEmptyAPI, buscarDados, buscarTimesheetAPI, updateTimesheetAPI };
