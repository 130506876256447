import React, { useState, useEffect } from "react";
import TimePicker from "./TimePicker";

const TimeEntry = ({ date, entryTime, exitTime, description, onChange }) => {
  const [localEntryTime, setLocalEntryTime] = useState(entryTime);
  const [localExitTime, setLocalExitTime] = useState(exitTime);
  const [localDescription, setLocalDescription] = useState(description);

  useEffect(() => {
    setLocalEntryTime(entryTime);
    setLocalExitTime(exitTime);
    setLocalDescription(description);
  }, [entryTime, exitTime, description]); // Dependências para atualizar o estado local

  const handleEntryTimeChange = (time) => {
    setLocalEntryTime(time);
    onChange({ date, entryTime: time, exitTime: localExitTime, description: localDescription });
  };

  const handleExitTimeChange = (time) => {
    setLocalExitTime(time);
    onChange({ date, entryTime: localEntryTime, exitTime: time, description: localDescription });
  };

  const handleDescriptionChange = (description) => {
    setLocalDescription(description);
    onChange({ date, entryTime: localEntryTime, exitTime: localExitTime, description });
  };

  return (
    <div className="border border-gray-300 p-4 rounded-lg shadow-lg m-2 w-80 bg-white">
      <p className="text-lg font-semibold text-center text-gray-800  mb-3">{date}</p>
      <div className="flex flex-col items-center gap-4">
        <TimePicker label="Entry Time" time={localEntryTime} setTime={handleEntryTimeChange} />
        <TimePicker label="Exit Time" time={localExitTime} setTime={handleExitTimeChange} />
        <textarea
          value={localDescription}
          onChange={(e) => handleDescriptionChange(e.target.value)}
          placeholder="Add a description..."
          className="w-full p-2 border border-gray-300 rounded-md resize-none bg-gray-100 focus:ring focus:ring-blue-300"
          rows="3"
        />
      </div>
    </div>
  );
};

export default TimeEntry;