import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./layouts/Layout.js";
import LayoutPrint from "./layouts/LayoutPrint.js";
import LayoutTimesheet from "./layouts/LayoutTimesheet.js";
import Customer from "./components/customer/Customers";
import Service from "./components/service/Service";
import Address from "./components/address/Address";
import MyRoutes from "./components/routes/MyRoutes";
import Invoices from "./components/invoices/Invoices";
import Projetos from "./components/projetos/Projetos";
import NoPage from "./components/NoPage";
import Login from "./components/Login";
import './index.css';
import Home from "./components/home/Home";
import './components/Interceptor2.js';
import Invoice from "./components/invoices/Invoice.js";
import RouteInterceptor from "./components/RouteInterceptor"; // Importa o RouteInterceptor
import TimeTrackingApp from "./components/timesheet/TimeTrackingApp.jsx";
export default function App() {

  return (
    <BrowserRouter>
      <RouteInterceptor />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Login />} />
          <Route path="*" element={<NoPage />} />
          <Route path="login" element={<Login />} />
          <Route path="clientes" element={<Customer />} />
          <Route path="services" element={<Service />} />
          <Route path="address" element={<Address />} />
          <Route path="Routes" element={<MyRoutes />} />
          <Route path="invoices" element={<Invoices />} />
          <Route path="projetos" element={<Projetos />} />
          <Route path="home" element={<Home />} />
          <Route path="print" element={<Home />} />
        </Route>
        <Route path="/print" element={<LayoutPrint />}>
          <Route index element={<Invoice />} /> 
        </Route>
        <Route path="/timesheet" element={<LayoutTimesheet />}>
        <Route path=":year/:month/:userId" element={<TimeTrackingApp />} /> 
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);