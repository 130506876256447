import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { Checkbox, FormControlLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import env from '../../config';

function ServiceDialog({    
    open,
    onClose,
    saveSuccess,
    object,
    setObject
}) {
    const backendUrl = env.backendUrl;
    const entity = 'servicos';
    const initialState = {
        rota_tipo: '',
        valor: '',
        rota_friday: ''
    }
    const [objectFall, setObjectFall] = useState(initialState);

    const handleChange = (event) => { 
        setObject({...object, [event.target.name]: event.target.value});
    };

    const handleChangeFall = (event) => { 
        setObjectFall({...objectFall, [event.target.name]: event.target.value});
    };
    
    const handleMonthChange = (event) => { 
        if(event.target.checked){
            setObject({...object, [event.target.name]: 'fall'});
        }else{
            setObject({...object, [event.target.name]: null});
        }
    };

    const handleSave = () => {
        if( object.fall === '1' ){
            object.frequencia_quando_fall = calcularFrequenciaQuandoFall();
        }
        
        object.frequencia = calcularFrequencia();
        let project_id = localStorage.getItem('project_id');
        object.projeto_id = project_id;
        
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        
        if (object.id) {
            // object.frequencia = 'aaa';
            const data = JSON.stringify(object);

            const url = backendUrl + '/' + entity + '/' + object.id;
            axios.put(url, data, config)
                .then(response => {
                    if (response.status === 200) {
                        saveSuccess();
                    }
                    else {
                        alert('Something went wrong!');
                    }
                })
                .catch(err => console.error(err))
        } else {
            const data = JSON.stringify(object);
            const url = backendUrl + '/' + entity;
            axios.post(url, data, config)
                .then(response => {
                    if (response.status === 200) {
                        saveSuccess();
                    }
                    else {
                        alert('Something went wrong!');
                    }
                })
                .catch(err => console.error(err))
        }

   
    }

    const calcularFrequencia = () => {
        let resultado = '';
  
        // Caso a frequência seja mensal
        if (object.rota_tipo === 'mensal') {
            const dias = object.rota_mensal_dia;
            const quantidadeDias = dias.split(';').length;

            // Extraindo o primeiro dia para compor a mensagem
            const primeiroDia = dias.split(';')[0].split('-')[1];

            // Gerar a descrição da frequência mensal
            resultado = `${quantidadeDias} DAY(S) PER MONTH - ${primeiroDia.toUpperCase()}`;
        }

        // Caso a frequência seja semanal
        else if (object.rota_tipo === 'semanal') {
            let diasSemanais = [];

            // Checando quais dias estão marcados
            if (object.rota_monday) diasSemanais.push('MONDAY');
            if (object.rota_tuesday) diasSemanais.push('TUESDAY');
            if (object.rota_wednesday) diasSemanais.push('WEDNESDAY');
            if (object.rota_thursday) diasSemanais.push('THURSDAY');
            if (object.rota_friday) diasSemanais.push('FRIDAY');
            if (object.rota_saturday) diasSemanais.push('SATURDAY');
            if (object.rota_sunday) diasSemanais.push('SUNDAY');

            const quantidadeDiasSemanais = diasSemanais.length;

            // Gerar a descrição da frequência semanal
            resultado = `${quantidadeDiasSemanais} DAY(S) PER WEEK - ${diasSemanais.join('/')}`;
        }else{
            resultado = object.frequencia;
        }

        return resultado;
    }

    const calcularFrequenciaQuandoFall = () => {
        let resultado = '';
  
        // Caso a frequência seja Monthly
        if (objectFall.rota_tipo === 'Monthly') {
            const dias = objectFall.rota_Monthly_dia;
            const quantidadeDias = dias.split(';').length;

            // Extraindo o primeiro dia para compor a mensagem
            const primeiroDia = dias.split(';')[0].split('-')[1];

            // Gerar a descrição da frequência Monthly
            resultado = `${quantidadeDias} DAY(S) PER MONTH - ${primeiroDia.toUpperCase()}`;
        }

        // Caso a frequência seja Weekly
        else if (objectFall.rota_tipo === 'Weekly') {
            let diasSemanais = [];

            // Checando quais dias estão marcados
            if (objectFall.rota_monday) diasSemanais.push('MONDAY');
            if (objectFall.rota_tuesday) diasSemanais.push('TUESDAY');
            if (objectFall.rota_wednesday) diasSemanais.push('WEDNESDAY');
            if (objectFall.rota_thursday) diasSemanais.push('THURSDAY');
            if (objectFall.rota_friday) diasSemanais.push('FRIDAY');
            if (objectFall.rota_saturday) diasSemanais.push('SATURDAY');
            if (objectFall.rota_sunday) diasSemanais.push('SUNDAY');

            const quantidadeDiasSemanais = diasSemanais.length;

            // Gerar a descrição da frequência Weekly
            resultado = `${quantidadeDiasSemanais} DAY(S) PER WEEK - ${diasSemanais.join('/')}`;
        }else{
            resultado = objectFall.frequencia;
        }

        return resultado;
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
            <DialogTitle>Service</DialogTitle>
            <DialogContent>
                <Stack spacing={2} mt={1}>
                    {/* Campos obrigatórios */}
                    <TextField
                        label="Category (*)"
                        name="titulo"
                        select
                        variant="standard"
                        value={object.titulo || ''}
                        onChange={handleChange}
                    >
                        {["Power Sweeping", "Day Porter", "Pressure Wash", "Janitorial Services",
                            "Landscaping", "Graffiti Removal", "Trash Removal", "Magnetic Sweeper", "Other"].map(option => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                    </TextField>

                    <TextField
                        label="Value (*)"
                        name="valor"
                        type="number"
                        variant="standard"
                        value={object.valor}
                        onChange={handleChange}
                    />

                    <TextField
                        label="Type (*)"
                        name="rota_tipo"
                        select
                        variant="standard"
                        value={object.rota_tipo || ''}
                        onChange={(e) => {
                            // setObject({
                            //   ...object,
                            //   rota_tipo: e.target.value,
                            // });

                            // console.info("cleaning");

                            object.rota_mensal_dia = null;
                            object.frequencia = '';
                            object.rota_sunday = null;
                            object.rota_monday = null;
                            object.rota_tuesday = null;
                            object.rota_wednesday = null;
                            object.rota_thursday = null;
                            object.rota_friday = null;
                            object.rota_saturday = null;

                            handleChange(e);
                        }}


                    // {(e) => handleChange({ target: [{ name: 'rota_tipo', value: e.target.checked },
                    //                                           name: 'rota_mensal_dia,' value: 'XXX' }])}
                    >
                        {["mensal", "semanal", "trimestre"].map(option => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>

                    {/* Campos opcionais */}
                    {object.rota_tipo === 'mensal' && (
                        <TextField
                            label="Monthly Route Day"
                            name="rota_mensal_dia"
                            select
                            variant="standard"
                            value={object.rota_mensal_dia || ''}
                            onChange={handleChange}
                        >
                            {[
                                "1-friday", "1-friday;3-friday;5-friday", "1-monday", "1-monday;3-monday",
                                "1-monday;3-monday;5-monday", "1-saturday", "1-saturday;3-saturday",
                                "1-saturday;3-saturday;5-saturday", "1-sunday", "1-sunday;3-sunday",
                                "1-thursday", "1-thursday;3-thursday", "1-tuesday", "1-tuesday;3-tuesday",
                                "1-wednesday", "1-wednesday;3-wednesday", "2-saturday", "2-saturday;4-saturday", "3-monday"
                            ].map(option => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}

                    {/* Campos semanais */}
                    {object.rota_tipo === 'semanal' && (
                        <div style={{ backgroundColor: '#d0edff', padding: '20px' }}>
                            <h4>Select days of week</h4>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={object.rota_sunday === '1'}
                                        onChange={(e) => setObject({
                                            ...object,
                                            rota_sunday: e.target.checked ? '1' : null, // Define 1 se marcado, null se desmarcado
                                        })}
                                        name="rota_sunday"
                                    />
                                }
                                label="SUNDAY"
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={object.rota_monday === '1'}
                                        onChange={(e) => setObject({
                                            ...object,
                                            rota_monday: e.target.checked ? '1' : null, // Define 1 se marcado, null se desmarcado
                                        })}
                                        name="rota_monday"
                                    />
                                }
                                label="MONDAY"
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={object.rota_tuesday === '1'}
                                        onChange={(e) => setObject({
                                            ...object,
                                            rota_tuesday: e.target.checked ? '1' : null, // Define 1 se marcado, null se desmarcado
                                        })}
                                        name="rota_tuesday"
                                    />
                                }
                                label="TUESDAY"
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={object.rota_wednesday === '1'}
                                        onChange={(e) => setObject({
                                            ...object,
                                            rota_wednesday: e.target.checked ? '1' : null, // Define 1 se marcado, null se desmarcado
                                        })}
                                        name="rota_wednesday"
                                    />
                                }
                                label="WEDNESDAY"
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={object.rota_thursday === '1'}
                                        onChange={(e) => setObject({
                                            ...object,
                                            rota_thursday: e.target.checked ? '1' : null, // Define 1 se marcado, null se desmarcado
                                        })}
                                        name="rota_thursday"
                                    />
                                }
                                label="THURSDAY"
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={object.rota_friday === '1'} // Verifica se o valor é 1
                                        onChange={(e) => setObject({
                                            ...object,
                                            rota_friday: e.target.checked ? '1' : null, // Define 1 se marcado, null se desmarcado
                                        })}
                                        name="rota_friday"
                                    />
                                }
                                label="FRIDAY"
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={object.rota_saturday === '1'}
                                        onChange={(e) => setObject({
                                            ...object,
                                            rota_saturday: e.target.checked ? '1' : null, // Define 1 se marcado, null se desmarcado
                                        })}
                                        name="rota_saturday"
                                    />
                                }
                                label="SATURDAY"
                            />
                        </div>
                    )}

                    {object.rota_tipo === 'trimestre' && (
                        <div style={{ backgroundColor: '#d0edff', padding: '20px' }}>
                            <h4>Select frequency Quarterly</h4>
                            <div>
                                <TextField
                                    label="Frequency (*)"
                                    name="frequencia"
                                    select
                                    variant="standard"
                                    value={object.frequencia || ''}
                                    onChange={handleChange}
                                >
                                    {[
                                        // "2 DAYS PER WEEK (Wednesday and Friday)",
                                        // "2X A WEEK (MONDAY, THURSDAY)",
                                        // "3 DAY PER WEEK (monday, wednesday, friday)",
                                        // "3 DAYS PER WEEK - Monday, Wednesday, and Friday",
                                        // "3 DAYS PER WEEK (Tuesday, Thursday, Saturday)",
                                        // "3X PER WEEK (MONDAY, WEDNESDAY, FRIDAY)",
                                        // "3X WEEK - MONDAY, WEDNESDAY, FRIDAY",
                                        // "4X PER WEEK (TUESDAY, THURSDAY, SATURDAY & SUNDAY)",
                                        // "5 DAYS PER WEEK - MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY",
                                        // "EVERY OTHER WEEK",
                                        // "EVERY OTHER WEEK - FRIDAY",
                                        // "EVERY OTHER WEEK - SATURDAY",
                                        // "EVERY OTHER WEEK - SATURDAYS",
                                        // "MONDAY, TUESDAY, WEDNESDAY, FRIDAY",
                                        // "MONDAY, WEDNESDAY, FRIDAY",
                                        // "MONDAY, WEDNESDAY, THURSDAY, FRIDAY",
                                        // "ONCE A MONTH",
                                        // "ONCE A MONTH - MONDAY",
                                        // "ONCE A MONTH - TUESDAY",
                                        // "ONCE A MONTH - WEDNESDAY",
                                        // "ONCE A MONTH - THURSDAY",
                                        // "ONCE A MONTH - FRIDAY",
                                        // "ONCE A MONTH - SATURDAY",
                                        // "ONCE A MONTH - SUNDAY",
                                        // "ONCE A WEEK - MONDAYS",
                                        // "ONCE A WEEK - TUESDAYS",
                                        // "ONCE A WEEK - WEDNESDAY",
                                        // "ONCE A WEEK - THURSDAYS",
                                        // "ONCE A WEEK - SATURDAYS",
                                        // "ONCE A WEEK - FRIDAYS",
                                        // "ONCE A WEEK - SUNDAYS",
                                        // "TWICE A MONTH - MONDAYS",
                                        // "TWICE A MONTH - TUESDAYS",
                                        // "TWICE A MONTH - WEDNESDAYS",
                                        // "TWICE A MONTH - THURSDAYS",
                                        // "TWICE A MONTH - SATURDAYS",
                                        // "TWICE A MONTH - SUNDAYS",
                                        // "TWICE A WEEK - FRIDAY / MONDAY",
                                        // "TWICE A WEEK - MONDAY / THURSDAYS",
                                        // "TWICE A WEEK - TUESDAY / FRIDAY",
                                        // "TWICE A WEEK - WEDNESDAY / FRIDAY",
                                        // "TWICE A WEEK - WEDNESDAY / SATURDAY",
                                        "Jan/Apr/Jul/Oct",
                                        "Fev/Mai/Aug/Nov",
                                        "Mar/Jun/Sep/Dec"
                                    ].map(option => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                    )}

                    {/* <FormControlLabel
                        control={
                            <Checkbox
                                checked={object.fall === '1'}
                                onChange={(e) => {
                                    if(e.target.checked){
                                        object.valor_quando_fall = '';
                                        object.mes_1 = null;
                                        object.mes_2 = null;
                                        object.mes_3 = null;
                                        object.mes_4 = null;
                                        object.mes_5 = null;
                                        object.mes_6 = null;
                                        object.mes_7 = null;
                                        object.mes_8 = null;
                                        object.mes_9 = null;
                                        object.mes_10 = null;
                                        object.mes_11 = null;
                                        object.mes_12 = null;
                                    }else{
                                        object.valor_quando_fall = null;
                                    }
        
                                    handleChange({ target: { name: 'fall', value: e.target.checked } });
                                }}
                                name="fall"
                            />
                        }
                        label="Fall"
                    /> */}

                    {/* Campos de datas */}
                    {object.fall === '1' && (
                        <div style={{ backgroundColor: '#d0edff', padding: '20px' }}>
                            <h4>Fill with fall information</h4>
                            <TextField
                                label="Value when fall (*)"
                                name="valor_quando_fall"
                                type="number"
                                variant="standard"
                                value={object.valor_quando_fall}
                                onChange={handleChange}
                                
                            />

                            <TextField
                                label="Type (*)"
                                name="rota_tipo"
                                select
                                variant="standard"
                                value={objectFall.rota_tipo || ''}
                                onChange={(e) => {
                                    setObjectFall({...objectFall, rota_tipo: e.target.value });

                                    if(objectFall.rota_tipo === 'Monthly'){
                                        objectFall.rota_sunday = null;
                                        objectFall.rota_monday = null;
                                        objectFall.rota_tuesday = null;
                                        objectFall.rota_wednesday = null;
                                        objectFall.rota_thursday = null;
                                        objectFall.rota_friday = null;
                                        objectFall.rota_saturday = null;
                                    }else{
                                        objectFall.rota_mensal_dia = '';
                                    }

                                    handleChangeFall(e);
                                }}
                            >
                                {["Monthly", "Weekly"].map(option => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>

                            {/* Campos opcionais */}
                            {objectFall.rota_tipo === 'Monthly' && (
                                <TextField
                                    label="Monthly Route Day"
                                    name="rota_mensal_dia"
                                    select
                                    variant="standard"
                                    value={objectFall.rota_mensal_dia || ''}
                                    onChange={handleChangeFall}
                                >
                                    {[
                                        "1-friday", "1-friday;3-friday;5-friday", "1-monday", "1-monday;3-monday",
                                        "1-monday;3-monday;5-monday", "1-saturday", "1-saturday;3-saturday",
                                        "1-saturday;3-saturday;5-saturday", "1-sunday", "1-sunday;3-sunday",
                                        "1-thursday", "1-thursday;3-thursday", "1-tuesday", "1-tuesday;3-tuesday",
                                        "1-wednesday", "1-wednesday;3-wednesday", "2-saturday", "2-saturday;4-saturday"
                                    ].map(option => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}

                            {/* Campos semanais */}
                            {objectFall.rota_tipo === 'Weekly' && (
                                <div style={{ backgroundColor: '#d0edff', padding: '20px' }}>
                                    <h4>Select days of week</h4>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={objectFall.rota_sunday === '1'}
                                                onChange={(e) => setObjectFall({
                                                    ...objectFall,
                                                    rota_sunday: e.target.checked ? '1' : null, // Define 1 se marcado, null se desmarcado
                                                })}
                                                name="rota_sunday"
                                            />
                                        }
                                        label="SUNDAY"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={objectFall.rota_monday === '1'}
                                                onChange={(e) => setObjectFall({
                                                    ...objectFall,
                                                    rota_monday: e.target.checked ? '1' : null, // Define 1 se marcado, null se desmarcado
                                                })}
                                                name="rota_monday"
                                            />
                                        }
                                        label="MONDAY"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={objectFall.rota_tuesday === '1'}
                                                onChange={(e) => setObjectFall({
                                                    ...objectFall,
                                                    rota_tuesday: e.target.checked ? '1' : null, // Define 1 se marcado, null se desmarcado
                                                })}
                                                name="rota_tuesday"
                                            />
                                        }
                                        label="TUESDAY"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={objectFall.rota_wednesday === '1'}
                                                onChange={(e) => setObjectFall({
                                                    ...objectFall,
                                                    rota_wednesday: e.target.checked ? '1' : null, // Define 1 se marcado, null se desmarcado
                                                })}
                                                name="rota_wednesday"
                                            />
                                        }
                                        label="WEDNESDAY"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={objectFall.rota_thursday === '1'}
                                                onChange={(e) => setObjectFall({
                                                    ...objectFall,
                                                    rota_thursday: e.target.checked ? '1' : null, // Define 1 se marcado, null se desmarcado
                                                })}
                                                name="rota_thursday"
                                            />
                                        }
                                        label="THURSDAY"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={objectFall.rota_friday === '1'} // Verifica se o valor é 1
                                                onChange={(e) => setObjectFall({
                                                    ...objectFall,
                                                    rota_friday: e.target.checked ? '1' : null, // Define 1 se marcado, null se desmarcado
                                                })}
                                                name="rota_friday"
                                            />
                                        }
                                        label="FRIDAY"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={objectFall.rota_saturday === '1'}
                                                onChange={(e) => setObjectFall({
                                                    ...objectFall,
                                                    rota_saturday: e.target.checked ? '1' : null, // Define 1 se marcado, null se desmarcado
                                                })}
                                                name="rota_saturday"
                                            />
                                        }
                                        label="SATURDAY"
                                    />
                                </div>
                            )}

                            {/* Campos mensais */}
                            <br />
                            <br />
                            <div>
                                <h4>Select months</h4>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={object.mes_1 === "fall"}
                                            onChange={handleMonthChange}
                                            name="mes_1"
                                        />
                                    }
                                    label="January"
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={object.mes_2 === "fall"}
                                            onChange={handleMonthChange}
                                            name="mes_2"
                                        />
                                    }
                                    label="February"
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={object.mes_3 === "fall"}
                                            onChange={handleMonthChange}
                                            name="mes_3"
                                        />
                                    }
                                    label="March"
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={object.mes_4 === "fall"}
                                            onChange={handleMonthChange}
                                            name="mes_4"
                                        />
                                    }
                                    label="April"
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={object.mes_5 === "fall"}
                                            onChange={handleMonthChange}
                                            name="mes_5"
                                        />
                                    }
                                    label="May"
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={object.mes_6 === "fall"}
                                            onChange={handleMonthChange}
                                            name="mes_6"
                                        />
                                    }
                                    label="June"
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={object.mes_7 === "fall"}
                                            onChange={handleMonthChange}
                                            name="mes_7"
                                        />
                                    }
                                    label="July"
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={object.mes_8 === "fall"}
                                            onChange={handleMonthChange}
                                            name="mes_8"
                                        />
                                    }
                                    label="August"
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={object.mes_9 === "fall"}
                                            onChange={handleMonthChange}
                                            name="mes_9"
                                        />
                                    }
                                    label="September"
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={object.mes_10 === "fall"}
                                            onChange={handleMonthChange}
                                            name="mes_10"
                                        />
                                    }
                                    label="October"
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={object.mes_11 === "fall"}
                                            onChange={handleMonthChange}
                                            name="mes_11"
                                        />
                                    }
                                    label="November"
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={object.mes_12 === "fall"}
                                            onChange={handleMonthChange}
                                            name="mes_12"
                                        />
                                    }
                                    label="December"
                                />
                            </div>

                        </div>
                    )}

                    {/* Repita para os outros meses, mes_2, mes_3, etc... */}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ServiceDialog;
